<template>
  <div id="appbar">
    <div class="flex full-height" style="align-items: center">
      <div
        class="full-height flex"
        style="
          min-width: 240px;
          align-items: center;
          justify-content: center;
          box-sizing: border-box;
        "
      >
        <img src="@/assets/avassa-logo-white.svg" width="200" height="34" />
      </div>
    </div>
    <span style="color: white; padding-right: 88px;">ADMIN UI</span>
    <div class="user-menu pr-16">
      <div
        class="flex-column"
        style="justify-content: center; align-items: flex-end"
      >
        <div style="text-align: right">{{ profile.name }}</div>
        <div style="font-weight: 400">
          {{ profile["https://avassa.io/roles"]?.toString().replace("_", " ") }}
        </div>
      </div>
      <div
        class="body-bold text-white"
        style="padding-right: 8px; margin-top: -2px"
      ></div>
      <DropDownMenu />
    </div>
  </div>
</template>
<script>
import DropDownMenu from "@/components/DropDownMenu";
import useUser from "@/modules/useUser";

export default {
  components: { DropDownMenu },

  setup() {
    const { userState } = useUser();
    return { profile: userState?.profile };
  },
};
</script>
<style lang="scss" scoped>
.navigation {
  & > a {
    color: var(--gray-90);
  }
}
.user-menu {
  display: flex;
  align-items: center;
  color: white;
}
#appbar {
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ee4c72;
}
</style>
