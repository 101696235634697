import useUser from "@/modules/useUser";

export function cookies() {
  return Object.fromEntries(
    document.cookie.split(/; */).map((c) => {
      const [key, v] = c.split(/=(.+)/, 2);
      return [key, decodeURIComponent(v)];
    })
  );
}

export const host = `${window.location.protocol}//${window.location.host}`;

/*
 * SO to the rescue
 */
export const clickOutside = {
  beforeMount: (el, binding) => {
    el.eventSetDrag = () => {
      el.setAttribute("data-dragging", "yes");
    };
    el.eventClearDrag = () => {
      el.removeAttribute("data-dragging");
    };
    el.eventOnClick = (event) => {
      const dragging = el.getAttribute("data-dragging");
      // Check that the click was outside the el and its children, and wasn't a drag
      if (!(el == event.target || el.contains(event.target)) && !dragging) {
        // call method provided in attribute value
        binding.value(event);
      }
    };
    document.addEventListener("touchstart", el.eventClearDrag);
    document.addEventListener("touchmove", el.eventSetDrag);
    document.addEventListener("click", el.eventOnClick);
    document.addEventListener("touchend", el.eventOnClick);
  },
  unmounted: (el) => {
    document.removeEventListener("touchstart", el.eventClearDrag);
    document.removeEventListener("touchmove", el.eventSetDrag);
    document.removeEventListener("click", el.eventOnClick);
    document.removeEventListener("touchend", el.eventOnClick);
    el.removeAttribute("data-dragging");
  },
};

export const delay = (time) => new Promise((res) => setTimeout(res, time));

export const apiUrl = () => {
  return `https://api.${window.location.host.replaceAll(
    /local\.|admin\.|:8080|:8081$/g,
    ""
  )}`;
};

export const get = async (path) => {
  const res = await fetch(`${apiUrl()}${path}`, {
    credentials: "include",
  });
  assertAuthenticated(res);
  return res;
};

export const assertAuthenticated = ({ status }) => {
  if (status === 401 || status === 403) {
    const { sessionTimeout } = useUser();
    sessionTimeout.value = true;
  }
};

export const post = async (path, data) => {
  const res = await fetch(`${apiUrl()}${path}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    credentials: "include",
    method: "POST",
    body: JSON.stringify(data),
  });
  assertAuthenticated(res);
  return res;
};

export const logout = () =>
  (window.location = `${apiUrl()}/logout?redirectUrl=${host}/`);

export const parseJwt = (token) => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

export const env = process.env.VUE_APP_ENVIRONMENT;
