import { createRouter, createWebHistory } from "vue-router";
import Page from "@/views/Page";
import PageNotFound from "@/views/PageNotFound";
import Home from "@/views/Home";
import ListOrganizations from "@/views/ListOrganizations";
import { apiUrl, host } from "@/utils";
import useUser from "@/modules/useUser";
import Unauthorized from "@/views/Forbidden";

const { userState } = useUser();

const onAuthRequired = async (to, from, next) => {
  if (userState.isAdmin) {
    next(); // user shall pass
  } else if (userState.isAuthenticated) {
    next("/unauthorized");
  } else {
    window.location = `${apiUrl()}/login?redirectUrl=${host}${encodeURIComponent(
      to.fullPath
    )}`;
    next(false);
  }
};

const routes = [
  { path: "/:pathMatch(.*)*", component: PageNotFound },
  { path: "/unauthorized", component: Unauthorized },
  {
    path: "/home",
    name: "Home",
    component: Home,
  },
  {
    path: "/",
    name: "Page",
    component: Page,
    props: true,
    beforeEnter: onAuthRequired,
    children: [
      {
        path: "",
        name: "ListOrganizations",
        component: ListOrganizations,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
