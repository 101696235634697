import { reactive } from "vue";
import { cookies, parseJwt } from "@/utils";
import { computed, toRef } from "vue";

const userState = reactive({
  isAuthenticated: false,
  isAdmin: false,
  sessionTimeout: false,
  profile: undefined,
});

export default function useUser() {
  const startSessionWatchdog = () => {
    setInterval(() => {
      if (!cookies()["acp-authn"]) {
        userState.sessionTimeout = true;
      }
    }, 10000);
  };

  const init = () => {
    const userInfo = cookies()["acp-authn"];
    if (userInfo) {
      userState.isAuthenticated = true;
      userState.profile = parseJwt(userInfo);
      userState.isAdmin =
        userState.profile["https://avassa.io/roles"]?.includes("ACP_ADMIN");
      if (userState.isAdmin) {
        startSessionWatchdog();
      }
    }
  };
  return {
    isAuthenticated: computed(() => {
      return userState.isAuthenticated;
    }),
    sessionTimeout: toRef(userState, "sessionTimeout"),
    userState,
    init,
  };
}
