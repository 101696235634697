<template>
  <router-view v-slot="slotProps">
    <div v-if="env" class="env-banner">{{ env }}</div>
    <transition name="fade" mode="out-in">
      <component :is="slotProps.Component"></component>
    </transition>
  </router-view>
</template>

<script>
import { env } from "@/utils";
export default {
  name: "App",
  setup() {
    return { env };
  },
};
</script>

<style>
html {
  height: 100%;
  min-height: 100%;
}
body {
  min-height: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  color: var(--gray-90);
}
#app {
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: var(--gray-90);
  min-height: 100%;
  background-color: #f1f1f1;
  font-weight: 600;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: var(--gray-90);
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.env-banner {
  position: sticky;
  background: var(--secondary);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 24px;
  width: 100vw;
  height: 40px;
}
</style>
