<template>
  <div class="gradient-background full-width full-height">
    <div class="heading-1 banner">GOD MODE</div>
  </div>
</template>

<script>
export default {
  name: "Home",
  setup() {},
};
</script>
<style lang="scss" scoped>
.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.banner {
  position: absolute;
  bottom: 0;
  left: 138px;
  transform: rotate(-90deg);
  font-size: 13vh;
  transform-origin: bottom left;
  width: 100vh;
}
</style>
