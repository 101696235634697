import { ref } from "vue";
import { get, post } from "@/utils";
const organizations = ref([]);
const allowedEmails = ref([]);
const loading = ref(false);
const loaded = ref(false);
let timer = undefined;

export default function useOrganizations() {
  function toggleEligibleLevel(organizationId, production) {
    const eligibleLevel = production ? "PRODUCTION" : "TRIAL";
    post("/cloud-platform-admin-api/organizations/command/set-eligible-level", {
      organizationId,
      eligibleLevel,
    });
  }

  function fetchEmails() {
    get("/cloud-platform-admin-api/allowed-emails")
      .then((res) => res)
      .then((res) => res.json())
      .then((json) => {
        allowedEmails.value = json;
      });
  }
  function fetch() {
    loading.value = true;
    fetchEmails();
    get("/cloud-platform-admin-api/organizations")
      .then((res) => res)
      .then((res) => res.json())
      .then((json) => {
        organizations.value = json;
        loading.value = false;
        loaded.value = true;
      });
  }
  if (!timer) {
    timer = setInterval(fetch, 2000);
  }
  if (!loaded.value) {
    fetch();
  }
  const init = () => {};
  return {
    init,
    organizations,
    allowedEmails,
    loading,
    toggleEligibleLevel,
    loaded,
  };
}
