<template>
  <div class="full-width full-height gradient-background">
    <div id="appbara" class="text-white">
      <img src="@/assets/avassa-logo-white.svg" width="200" height="34" />
    </div>
    <div class="flex full-width full-height" style="justify-content: center">
      <div
        class="card-1 bg-white"
        style="
          width: 100%;
          max-width: 850px;
          height: 100%;
          max-height: 650px;
          justify-self: center;
        "
      >
        <div
          class="text-black heading-1 align-center pt-64"
          style="font-size: 72px"
        >
          404
        </div>
        <div class="text-black large-text pt-24 align-center">
          Is this the end of internet?
        </div>
        <div class="body align-center pt-24" style="color: var(--gray-70)">
          No, this place just simply don't exist, sorry ☹️
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
#appbara {
  height: 70px;
  display: flex;
  padding: 0 16px;
  justify-content: space-between;
  align-items: center;
}
</style>
