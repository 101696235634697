<template>
  <div class="counter">
    <div class="counter__title">
      <slot/>
    </div>
    <div class="counter__count">{{count}}</div>
  </div>
</template>

<script>
export default {
  props: {
    text: String,
    count: Number
  }
};
</script>

<style lang="scss" scoped>
.counter {
  display: flex;
  flex-direction: row;
  border: black solid 1px;
  border-radius: 16px;
  padding: 16px;
  margin-left: 16px;
  &__count {
    margin-left: 16px;
  }
}
</style>
