<template>
  <button
    :disabled="disabled"
    v-wave
    :class="{ btn: true, 'body-semi-bold': true, large: large }"
  >
    <slot></slot>
    <div v-show="loading" class="lds-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </button>
</template>
<script>
export default {
  props: ["disabled", "loading", "large"],
};
</script>
<style lang="scss" scoped>
.btn {
  max-height: 44px;
  padding: 9px 34px;
  white-space: nowrap;
  color: #fefefe;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 2px solid transparent;
  box-sizing: border-box;
  border-radius: 30px;
  &:hover {
    opacity: 0.9;
  }
  &:disabled {
    filter: grayscale(100%);
  }
  &:focus {
    outline: #79d2f3 solid 2px;
  }
  background: linear-gradient(90deg, #ee4c72 -61.61%, #f06255 51.37%);

  &.large {
    padding: 16px 34px;
    font-size: 18px;
    max-height: 57px;
  }
}

.lds-ring {
  display: inline-block;
  position: absolute;
  width: 28px;
  height: 28px;
  margin-left: 5px;
  right: 7px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 19px;
  height: 19px;
  margin: 5px;
  border: 2px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
