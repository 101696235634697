import { createApp } from "vue";
import VWave from "v-wave";
import "@/assets/css/main.scss";
import router from "@/router/router";
import { clickOutside } from "@/utils";
import App from "./App.vue";
import useUser from "@/modules/useUser";

const { init } = useUser();
init();

createApp(App)
  .use(router)
  .use(VWave)
  .directive("click-outside", clickOutside)
  .mount("#app");
