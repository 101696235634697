<template>
  <div>
    <AppBar style="grid-column: 1 / 13; grid-row: 1" />
    <div style="grid-column: 2 / 13; grid-row: 2">
      <div class="main-content">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
<script>
import AppBar from "@/components/AppBar";
export default {
  components: { AppBar },
};
</script>
<style scoped lang="scss">
.page-grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 0;
  grid-auto-rows: max-content;
}
.main-content {
  padding-bottom: 32px;
}
</style>
