<template>
  <div class="flex-column">
    <div
      style="position: relative; cursor: pointer; height: 47px; width: 47px"
      @click="toggleMenu"
    >
      <img src="@/assets/user-icon.svg" height="47" />
      <div
        v-click-outside="close"
        class="pl-24 pr-24 pt-16 pb-16 logout-link"
        style="
          position: absolute;
          right: 0;
          white-space: nowrap;
          box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
        "
        @click="logout"
        v-show="open"
      >
        Sign out
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { logout } from "@/utils";

export default {
  setup() {
    const open = ref(false);

    const close = () => {
      if (open.value) {
        open.value = false;
      }
    };

    const toggleMenu = (e) => {
      e.stopPropagation();
      open.value = !open.value;
    };
    return { toggleMenu, open, close, logout };
  },
};
</script>

<style lang="scss" scoped>
.logout-link {
  color: #0065c0;
  background-color: var(--gray-10);
  &:hover {
    background-color: var(--gray-30);
  }
}
</style>
