<template>
  <div class="full-width gradient-background" style="height: 100vh">
    <AppBar style="grid-column: 1 / 13; grid-row: 1" />
    <div
      class="flex full-width pt-48"
      style="justify-content: center; height: 500px"
    >
      <div
        class="card-1 bg-white"
        style="
          width: 100%;
          max-width: 850px;
          height: 100%;
          max-height: 650px;
          justify-self: center;
        "
      >
        <div
          class="text-black heading-1 align-center pt-64"
          style="font-size: 72px"
        >
          403
        </div>
        <div class="text-black large-text pt-24 align-center">
          This is when you ask to speak to your manager
        </div>
        <div class="body align-center pt-24" style="color: var(--gray-70)">
          Because it seems you don't have access to this 😟
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppBar from "@/components/AppBar";

export default {
  components: { AppBar },
};
</script>

<style lang="scss" scoped>
#appbara {
  height: 70px;
  display: flex;
  padding: 0 16px;
  justify-content: space-between;
  align-items: center;
}
</style>
